.App {

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-footer {
  height: 1.1cm;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  padding-top: 0.2cm;
  text-align: center;
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  text-decoration: none;
  color: orange;
}

.App-link {
  color: #61dafb;
}

.courses-page {
  padding-top: 1cm;
  padding-left: 4.5cm;
  padding-right: 4cm;
  background-color: #282c34;
  color: white;
  height: 100%;
  min-height: calc(100% - 1.1cm); /* Ensure content takes up the remaining height */
  margin-bottom: 1.1cm; /* Maintain space for the footer */
}

.courses-content {
  padding-bottom: 1cm;
}

.bc {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
  padding: 0 auto;
  font-weight: bold;
}

.bc-container {
  height: 1.5cm;
  background-color: black;
  padding-top: 0.3cm;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
